$dialog-background-color: #2b3c47;
.dialog-wrapper {
  display: flex;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;

  // Darken background behind first dialog wrapper
  &:first-child {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@include breakpoint($breakLarge) {
  .dialog-wrapper {
    position: fixed;
  }
}

// Darken dialogs behind other dialogs
.dialog-wrapper:not(:nth-last-of-type(1)) .dialog {
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.dialogRootContainer .dialog {
  display: block;
  animation: fadeIn 0.2s linear;
}

.dialog {
  display: none;
  position: relative;
  width: 100%;
  margin: 0 8px;
  padding: $dialog-padding;
  background-color: $dialog-background-color;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;

  &.bet-config,
  &.jokerwiz {
    max-width: 640px;
  }

  &.settings {
    max-width: 640px;
  }

  &.login {
    width: 280px;
  }

  &.error,
  &.alert,
  &.live-no-alias,
  &.confirm {
    max-width: 450px;
  }

  &.bet-template .help-icon,
  &.confirm .help-icon {
    margin-left: 4px;
  }

  &.export,
  &.export-dialog-sport,
  &.publish-dialog-sport,
  &.html-export,
  &.bet-template,
  &.coupons {
    max-width: 490px;
  }

  &.archive {
    max-width: 605px;
  }

  &.dialog-help {
    max-width: 450px;
  }

  .dialog-title {
    display: inline-block;
    margin: 4px 0 6px;
    font-size: 18px;
    font-weight: normal;

    &--with-help {
      display: inline-flex;
      align-items: center;
    }
  }

  hr {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    border: 0;
    background-color: #292929;
  }

  // Hide double divider caused by removed content inbetween
  hr + hr {
    display: none;
  }

  p,
  .text-body {
    margin: 0 0 16px;
    line-height: 1.5;
  }

  hr + p,
  hr + .text-body {
    margin-top: 16px;
  }

  h2 {
    margin: 16px 0;
    font-size: 14px;
    font-weight: normal;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
  .button-list {
    max-width: 240px;
    margin: 26px auto 0;

    li {
      display: inline-block;
      margin: 0 0 16px;
      text-align: center;

      &.secondary {
        a {
          font-size: 10px;
        }
      }

      a,
      button {
        display: inline-block;
        color: #fff;
        font-size: 12px;
        text-decoration: none;
        white-space: nowrap;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
        }

        span.sprite {
          margin-top: 4px;
          margin-bottom: 8px;
        }

        span.label {
          display: block;
        }

        @include hover {
          .sprite {
            opacity: $hover-opacity;
          }
          .title {
            color: $hover-white;
          }
        }
      }
    }
  }

  .button-list + .button-list {
    margin-top: 6px;
  }

  .styled-select {
    &.w50 select {
      width: 50px;
    }

    &.w80 select {
      width: 80px;
    }
    &.w125 select {
      width: 125px;
    }
  }

  @include breakpoint($breakMedium) {
    padding: $dialog-padding-breakMedium;

    h2 {
      font-size: 16px;
    }
  }
}

.fgrid--dialog-form {
  align-items: center;
  margin: 20px 0 12px;

  > .fgrid-cell-label {
    flex: 0 0 40%;
    margin-bottom: 12px;
    white-space: nowrap;
  }

  > .fgrid-cell-field {
    flex: 0 0 60%;
    margin-bottom: 12px;
  }

  &.fgrid-dialog-form-wide-labels {
    > .fgrid-cell-label {
      flex-basis: 50%;
    }
    > .fgrid-cell-field {
      flex-basis: 50%;
    }
  }

  @include breakpoint($breakSmall) {
    > .fgrid-cell-label {
      flex-basis: 22%;
    }

    > .fgrid-cell-field {
      flex-basis: 30%;
      flex-grow: 1;
    }

    > .fgrid-cell:nth-of-type(3),
    > .fgrid-cell:nth-of-type(7) {
      flex-basis: 15%;
    }

    > .fgrid-cell:nth-of-type(4),
    > .fgrid-cell:nth-of-type(8) {
      flex-basis: 22%;
      flex-grow: 0;
    }

    &.fgrid-dialog-form-wide-labels {
      > .fgrid-cell-label {
        flex-basis: 26%;
      }

      > .fgrid-cell-field {
        flex-basis: 26%;
      }

      > .fgrid-cell:nth-of-type(3),
      > .fgrid-cell:nth-of-type(7) {
        flex-basis: 30%;
      }

      > .fgrid-cell:nth-of-type(4),
      > .fgrid-cell:nth-of-type(8) {
        flex-basis: 16%;
      }
    }
  }

  @include breakpoint($breakMedium) {
    &.fgrid-dialog-form-wide-labels {
      > .fgrid-cell:nth-of-type(3),
      > .fgrid-cell:nth-of-type(7) {
        flex-basis: 22%;
      }
    }

    > .fgrid-cell:nth-of-type(4),
    > .fgrid-cell:nth-of-type(8) {
      flex-basis: 22%;
    }
  }
}

.dialog.export {
  .button-list {
    max-width: none;
  }
}
.dialog.bet-config {
  .dropdown-after-label {
    display: inline-block;
    padding-left: 3px;
  }

  .fgrid--spelvarde-published {
    margin-bottom: 0;
    > .fgrid-cell-label {
      flex: auto 0 0;
      padding-right: 16px;
    }
    > .fgrid-cell-field {
      flex: auto 1 1;
    }
  }
  .fgrid--spelvarde-date {
    margin-top: 0;
  }

  .loader {
    $spinnerSize: 20px;

    .spinner {
      width: $spinnerSize;
      height: $spinnerSize;
      background-size: $spinnerSize $spinnerSize;
    }
  }

  @include breakpoint($breakSmall) {
    .fgrid--spelvarde-date {
      > .fgrid-cell-label {
        flex: auto 0 0;
        padding-right: 16px;
      }
      > .fgrid-cell-field {
        flex: auto 1 1;
      }
    }
  }

  @include breakpoint($breakMedium) {
    .fgrid--spelvarde-date {
      > .fgrid-cell-field {
        flex: auto 0 0;
        padding-right: 32px;
      }
    }
  }
}

.dialog-help {
  .dialog-help-header {
    margin-bottom: 15px;
    padding: 0 0 15px;
    border-bottom: 1px solid #fff;

    .dialog-help-icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      vertical-align: middle;
    }

    h2 {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }
  }
}

.dialog.bet-template {
  .bet-template-name-field {
    display: inline-flex;
    align-items: center;

    input {
      text-indent: 0;

      &:focus {
        outline: none;
      }
    }
  }

  .template-bet-type {
    display: inline-flex;
    align-items: center;
    height: 25px;
    padding-left: 5px;
    background-color: #fff;
    color: #8c8e98;
    font-size: 12px;
    font-weight: normal;
  }
}
